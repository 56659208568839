html,
body {
  padding: 0;
  margin: 0;
}

.mobile-100vh.fix-mobile-vh {
  height: 100vh;
  height: var(--vh, 1vh) * 100;
}
.mobile-min-100vh.fix-mobile-min-vh {
  min-height: 100vh;
  min-height: var(--vh, 1vh) * 100;
}

/* clears the 'X' from Internet Explorer */
input[type='search']::-ms-clear,
input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
